import React from 'react'

const Products = () => {
  return (
    <div className='sm:pl-10  pt-[150px] md:pl-[600px]  '>

      <div className='sm:w-200px sm:pl-5  md:pl-[10px] pt-5 pb-[-90px]'>
        <h3 className=' text-sky-500 font-semibold text-3xl text-center md:text-4xl '> One solution, multiple applications</h3>

      </div>
      
<div class=" p-1 md:p-20  md:w-[400px]  drop-shadow-2xl md:bg-white rounded-lg md:border-dashed md:border-2  shadow-lg   dark:border-sky-500">

<div>


 
    <div  className='grid grid-cols-3  grid-flow-row-dense w-[400px] h-[800] md:grid-cols-6 ga\p-4 sm:ml-20 md:w-[850px] md:h-[750px]   md:bg-sky-500 md:border-dotted border-white rounded-2xl  sm:flex-row'>
 
    <div className='col-span-4 md:col-start-2 md:col-span-4  pt-5  mr-10 ml-5 md:pt-5 md:pl-5'>
<a href="https://oassis.truedote.com" class="group block max-w-xs mx-auto rounded-lg p-6 bg-white ring-1 ring-slate-200 shadow-lg space-y-3 hover:bg-sky-500 hover:ring-sky-500 md:hover:hover:scale-150">
  <div class="flex items-center space-x-3 animate-pulse ">
    <svg class="h-6 w-6 stroke-sky-500 group-hover:stroke-white" fill="none" viewBox="0 0 24 24"></svg>
    <h3 class="text-sky-500 group-hover:text-white text-sm font-semibold">1.Trudeote LIMS</h3>
  </div>
  <p class="text-[#111827] group-hover:text-white text-sm">Intelligent Laboratory management systems . Assists in decision support to minimize errors in laboratory tests.Used in Research and medical facilities</p>
</a>
    </div>

    <div className='col-start-1 col-span-4 md:col-start-1 md:col-end-3 mr-10 ml-5  md:mx-5 pt-5  '>
    <a href="/" class="group block max-w-xs mx-auto rounded-lg p-6 bg-white ring-1 ring-slate-200 shadow-lg space-y-3 hover:bg-sky-500 hover:ring-sky-500 md:hover:hover:scale-150">
      <div class="flex items-center space-x-3">
        <svg class="h-6 w-6 stroke-sky-500 group-hover:stroke-white" fill="none" viewBox="0 0 24 24"></svg>
        <h3 class="text-purple-500 group-hover:text-white text-sm font-semibold text-right">2.Truedote EMR</h3>
      </div>
      <p class="text-[#111827] group-hover:text-white text-sm">Manages workflows from triage, diagnosis,laboratory to pharmacy by augmenting the caregiver's decision making</p>
    </a>
    </div>


    <div className='col-start-1 col-span-4 md:col-end-7 md:col-span-2 mr-10 ml-5 md:mx-5 pt-5 '>
    <a href="/" class="group block max-w-xs mx-auto rounded-lg p-6 bg-white ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-sky-500 md:hover:scale-150">
      <div class="flex items-center space-x-3">
        <svg class="h-6 w-6 stroke-sky-500 group-hover:stroke-white" fill="none" viewBox="0 0 24 24"></svg>
        <h3 class="text-sky-500 group-hover:text-white text-sm font-semibold">3.MyTruedote</h3>
      </div>
      <p class="text-[#111827] group-hover:text-white text-sm">Access qualified and private health services from anywhere anytime</p>
    </a>
    </div>

    <div className=' col-start-1 col-span-4 md:col-end-7  mr-10 ml-5 md:mx-10 pt-5 pb-20   '>
    <a href="/" class="group block max-w-xs mx-auto rounded-lg p-6 bg-white ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-sky-500 md:hover:scale-150">
      <div class="flex items-center space-x-3">
        <svg class="h-6 w-6 stroke-sky-500 group-hover:stroke-white" fill="none" viewBox="0 0 24 24"></svg>
        <h3 class="text-green-500 group-hover:text-white text-sm font-bold">4.Truebox</h3>
      </div>
      <p class=" group-hover:text-white text-sm text-[#111827]">Remote telepathology platform for small healthcare facilities.</p>
    </a>
    </div> 




    </div>
 </div>
</div>




    </div>
  )
}

export default Products