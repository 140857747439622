import Nav from "./components/Nav";
import Hero from "./components/Hero";
import Products from "./components/Products";
import ProductGuide from "./components/ProductGuide";
import Features from "./components/Features";
function App() {
  return (
    <div className ="bg-slate-100">
    <Nav/>
    <Hero/>
    <ProductGuide/> 
    <Products/>
    <Features/>
    
    </div>
  );
}

export default App;
