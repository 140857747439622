import React from 'react'

const Features = () => {
  return (

<div >

</div>


        
  )
}



export default Features