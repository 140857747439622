import React from 'react'

const ProductGuide = () => {
  return (
    <div>

<section class="  grid grid-cols-6 sm:pt-[40px] md:pt-[300px] md:pb-[100px] my-20 md:gap-y-90px bg-gradient-to-r from-[#ffedd5] to-[red]">



    <div className='col-start-2 col-span-2  bg-[#f0f9ff]  shadow-2xl py-4 px-4  max-w-screen-xl text-center lg:py-10 lg:px-12  border-8 border-white  rounded-tl-[100px] rounded-br-[100px]'>
    <a href="/" class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-green-600 dark:text-white hover:bg-gray-200 dark:hover:bg-purple-500" role="alert">
            <span class="text-sm font-medium">decision support</span> 
            <svg class="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
        </a>
        <h1 class="mb-4 text-4xl font-bold tracking-tight leading-none  md:text-5xl lg:text-4xl dark:text-green-600">Clinical Decision Support</h1>
        <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-30 dark:text-black">Utilize  decision support  to enable you to deliver quality care to 
        your patients, at every stage of healthcare delivery. </p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <a href="/" class="inline-flex justify-center items-center py-3 px-6 text-base font-medium text-center text-white rounded-lg bg-sky-500 hover:bg-purple-500 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                order now
                <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
            
           
        </div>
    </div>

    <div className='col-start-4 col-span-2  py-4 px-4  max-w-screen-xl text-center lg:py-16 lg:px-12 my-20 '>
    <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-40 dark:text-black  ">We augment the capacity of the healthcare provider to use accurate and provide certain medical decisions </p>
    </div>
    
    <div className='sm:col-start-2 sm:col-span-2    md:py-4 md:px-1  md:max-w-screen-xl md:text-center lg:py-16 lg:px-12 my-20 '>
    <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-40 dark:text-black  ">Let us enhance the accuracy, certainity and speed of diagnostics in your facility today. </p>
    </div>

    <div className='col-start-4 col-span-2  bg-[#f0f9ff]  shadow-2xl py-4 px-1  max-w-screen-xl text-center lg:py-16 lg:px-12 border-8 border-white  rounded-tl-[100px]  rounded-br-[100px]'>
    <a href="/" class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-green-600 dark:text-white hover:bg-gray-200 dark:hover:bg-purple-500" role="alert">
            <span class="text-sm font-medium">diagnotsics</span> 
            <svg class="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
        </a>
        <h1 class="mb-4 text-4xl font-bold tracking-tight leading-none  md:text-5xl lg:text-4xl dark:text-green-600">Intelligent Diagnotics</h1>
        <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-30 dark:text-black">Make decisions based on scientific evidence  </p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <a href="/" class="inline-flex justify-center items-center py-3 px-6 text-base font-medium text-center text-white rounded-lg bg-sky-500 hover:bg-purple-500 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                order now
                <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
            
           
        </div>
    </div>
</section>


    </div>
  )
}

export default ProductGuide