import React from 'react'
// import { useRef ,useEffect,useState} from 'react'
// import { useInView } from 'react-intersection-observer'
import Typed from 'react-typed'
import TruedoteBackground from '../assets/images/landing.png'
const Hero = () => {
  // const {ref,inView:myVisible,entry}=useInView(options)

// useEffect( ()=>{
//   const observer=new IntersectionObserver( (entries)=>{
// const entry=entries[0];
// setMyVisisble(entry.isIntersecting) 
//   }

//   ) 
//   observer.observe(myref.current);

// }
//  )
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className=' max-w-[1240] mx-auto grid md:grid-cols-2 pt-[250px] right-5 gap-8 '>

        <div className='' >



<div className='flex flex-col justify-center md:pt-[120px] md:pr-[100px] md:pb-[200px] '>
  <Typed  className=' md:ml-[100px] px-2 text-3xl  md:text-5xl    text-[#2563eb]  mx-auto font-semibold'  strings={['Improve the quality of care in your facility today!']} typeSpeed={150} backSpeed={180} loop/>      

  
        <p className='md:text-3xl sm:text-4xl text-2xl sm:py-1  ml-6 mr-10 text-center sm:text-center object-contain'>Quality diagnostics determine the quality of care. We use artificial intelligence to improve diagnostics in your medical or research facility. </p>
 
 <div className='flex flex-nowrap mx-auto py-10'>
 <button type="button"
  class=" text-white bg-purple-700 hover:bg-purple-800  hover:text-white font-semibold rounded-lg text-sm px-5 py-3 mb-2 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300">Get Started</button> 

      </div>
 
        </div>


        </div>

    


<div className="group relative  place-items-center sm:p-10 " >
        <img class="place-items-center  sm:object-contain sm:w-[300px] sm:h-[200px] md:w-[680px] md:h-[400px]  drop-shadow-2xl p-10 rounded-[50px] opacity-100 "
            src={TruedoteBackground} alt='/' />
        <div
            class="absolute  top-0 left-0  right-5 w-full rounded-[50px] flex flex-col justify-center items-center bg-green-500 pl-20 pr-20 pb-10 opacity-0 sm:group-hover:w-[200px] md:group-hover:w-[685px]  sm:h-[300px]  md:h-[420px] group-hover:opacity-100 duration-500">




      <div class=" flex-col grid md:grid-cols-3  sm:grid-cols-1 gap-4 place-items-center md:h-screen bg-center md:bg-cover  bg-no-repeat object-contain ">
			<div class="grid place-items-center mx-auto p-10   rounded-3xl space-y-10">
            <h1 class="text-3xl  text-white items-center">hello@truedote.com</h1>
    <div class="flex place-items-center  justify-center space-x-3">

    <button class="bg-blue-500 px-4 py-2 font-semibold text-white inline-flex items-center space-x-2 rounded"   onClick={() => openInNewTab('https://facebook.com/truedote')}>
        <svg class="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
        <span>Facebook</span>
    </button>
    <button class="bg-blue-500 px-4 py-2 font-semibold text-white inline-flex items-center space-x-2 rounded"   onClick={() => openInNewTab('https://twitter.com/truedotehealth')}>
        <svg class="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>
        <span>Twitter</span>
    </button>
   
  
    </div>
			</div>
		</div>




        </div>
    </div>
    </div>
  )
}

export default Hero