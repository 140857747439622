import React from 'react'
import Logo from '../assets/images/logo.svg'
import { useState } from "react";

const Nav = () => {
    let [open,setOpen]=useState(false);

  return (
    <div>

<nav className='bg-white shadow-sm border-slate-100'>

<div className='px-20 border mx-auto'>
    <div className='flex justify-between'>
{/* logo */}

        <div className=' flex space-x-6'>
            <div>
                <a href='/' className='items-center py-2 px-3'>
                <img src={Logo} className=" h-10 w-[200px] mr-10 " alt='/'/>

                </a>
            </div>

        </div>

        <div className='hidden md:flex items-center space-x-4  text-purple-700'>
           <a href='/' className='py-2 px-3  hover:text-blue-600 font-semibold'> Home</a>
           <a href='/' className='py-2 px-3  hover:text-blue-600 font-semibold'> Products</a>
           <a href='/' className='py-2 px-3  hover:text-blue-600 font-semibold'> Research</a>
           <a href='/' className='py-2 px-3  hover:text-blue-600 font-semibold'> Invest</a>
           <a href='/' className='py-2 px-3  hover:text-blue-600 font-semibold'> Blog</a>

            </div>

            <div className='hidden md:flex items-center space-x-1 '>
            <a href='/' className='py-2 px-3 font-medium '> Login </a>
            <a href='/' className='py-2 px-3 rounded-lg text-white transition ease-in-out delay-60 bg-green-600 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300'> Sign Up</a>


            </div>

            <div className='md:hidden flex items-center'>
            <div onClick={()=>setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden'>
      <ion-icon name={open ? 'close':'menu'}></ion-icon>
      </div>
            </div>

    </div>
    <div    className={`md:hidden border-spacing-2 space-y-3 border-green-500 ${
                            open ? "block" : "hidden"
                        }`}
                    >
<a href= "/" className='block py-2 text-sm text-purple-600 font-semibold hover:text-blue-600 '>Home</a>   
<a href= "/" className='block py-2 text-sm text-purple-600 font-semibold hover:text-blue-600'>Products</a>   
<a href= "/"  className='block py-2 text-sm text-purple-600 font-semibold hover:text-blue-600'> Research</a>   
<a href= "/"  className='block py-2 text-sm text-purple-600 font-semibold hover:text-blue-600'>Blog</a>   

</div>




</div>

</nav>

    </div>
  )
}

export default Nav

